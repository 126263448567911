/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine, RiCalendarCheckLine, RiTimeLine, RiParentLine, RiHeartLine } from "react-icons/ri"

const LeavePolicyPage = () => {
  return (
    <Layout>
      <SEO 
        title="Leave & Time Off Policy Template | Comprehensive PTO Guidelines"
        description="Download our customizable leave policy template covering vacation, sick leave, parental leave, and more with clear guidelines for request procedures and compliance requirements."
        keywords={[
          "leave policy template",
          "time off policy",
          "PTO guidelines",
          "vacation policy",
          "sick leave policy",
          "parental leave guidelines",
          "employee leave management"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Leave & Time Off Policy Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive, customizable policy template to establish clear guidelines for 
              various types of employee leave and time off in your organization.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Template <RiFileDownloadLine />
              </Link>
              
              <button type="button" onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#vacation-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Vacation Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#sick-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Sick Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#parental-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Parental Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#other-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Other Leave Types
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#request-procedure" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Request Procedure
                  </Link>
                </li>
                <li sx={{ mb: 0 }}>
                  <Link to="#compliance" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Legal Compliance
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  A comprehensive leave and time off policy is essential for establishing clear guidelines
                  and expectations regarding employee absences. This policy template outlines various
                  types of leave available to employees, eligibility criteria, request procedures, and
                  compliance requirements.
                </p>
                
                <p>
                  This template can be customized to align with your organization's specific needs,
                  culture, and applicable laws. It aims to create a fair, transparent, and legally
                  compliant framework for managing employee leave and time off.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Benefits of a Well-Structured Leave Policy:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Promotes work-life balance and employee wellbeing</li>
                    <li>Ensures consistent application of leave benefits</li>
                    <li>Reduces confusion and potential conflicts</li>
                    <li>Helps maintain adequate staffing levels</li>
                    <li>Demonstrates compliance with legal requirements</li>
                    <li>Establishes clear procedures for requesting and approving leave</li>
                    <li>Supports effective workforce planning</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Vacation Leave Section */}
            <div id="vacation-leave" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiCalendarCheckLine sx={{ color: 'primary' }} /> Vacation Leave
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Eligibility & Accrual
                </h3>
                
                <p>
                  [Company Name] provides paid vacation leave to eligible employees based on their employment 
                  status and length of service. This section outlines how vacation time is accrued, when it 
                  becomes available for use, and any limitations on accrual.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sample Accrual Schedule
                  </h4>
                  
                  <table sx={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    fontSize: '0.9rem'
                  }}>
                    <thead>
                      <tr>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Length of Service
                        </th>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Full-Time Employees
                        </th>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Part-Time Employees
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          0-1 year
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          10 days (80 hours)
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Prorated based on hours worked
                        </td>
                      </tr>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          1-5 years
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          15 days (120 hours)
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Prorated based on hours worked
                        </td>
                      </tr>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          5+ years
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          20 days (160 hours)
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Prorated based on hours worked
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Carryover & Payout
                </h3>
                
                <p>
                  [Define your policy regarding vacation carryover from one year to the next, maximum
                  accrual limits, and whether unused vacation time is paid out upon termination of employment.
                  Include any specific restrictions or limitations.]
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Carryover Example
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Employees may carry over up to 5 days (40 hours) of unused vacation time from one 
                      calendar year to the next. Any vacation time exceeding this limit will be forfeited 
                      unless an exception is approved by management.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Payout Example
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Upon termination of employment, employees will be paid for accrued, unused vacation time 
                      at their current rate of pay, provided they have completed at least six months of service 
                      and have given proper notice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Sick Leave Section */}
            <div id="sick-leave" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiHeartLine sx={{ color: 'primary' }} /> Sick Leave
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] provides paid sick leave to employees for absences due to illness, injury, 
                  medical appointments, or other health-related needs. This section outlines eligibility, 
                  accrual, usage guidelines, and documentation requirements for sick leave.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Sick Leave Allowance
                </h3>
                
                <p>
                  [Specify the amount of sick leave provided to employees, whether it is accrued over time 
                  or provided in a lump sum at the beginning of the year, and any waiting periods for new 
                  employees.]
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sample Sick Leave Policy
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li><strong>Full-time employees:</strong> Accrue 1 hour of sick leave for every 30 hours worked, up to a maximum of 40 hours per year.</li>
                    <li><strong>Part-time employees:</strong> Accrue sick leave proportional to hours worked, following the same rate as full-time employees.</li>
                    <li><strong>Waiting period:</strong> New employees are eligible to use accrued sick leave after 90 days of employment.</li>
                    <li><strong>Carryover:</strong> Up to 40 hours of unused sick leave may carry over to the following year.</li>
                    <li><strong>Maximum accrual:</strong> Employees may accrue a maximum of 80 hours of sick leave at any time.</li>
                  </ul>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Notification Requirements
                </h3>
                
                <p>
                  Employees are expected to notify their supervisor as soon as practicable when they
                  will be absent due to illness or injury. Preferably, this notification should occur
                  before the start of the employee's scheduled work hours.
                </p>
                
                <p>
                  For absences of three or more consecutive days, [Company Name] may require documentation
                  from a healthcare provider verifying the need for the absence and, if applicable, clearing
                  the employee to return to work.
                </p>
              </div>
            </div>

            {/* More sections would continue here */}
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Leave & Time Off Policy Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to implement a comprehensive 
                leave and time off policy for your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Leave request forms
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Tracking spreadsheets
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> State-specific guidelines
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Sample calendar templates
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Implementation checklists
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies/performance-management" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Previous: Performance Management
              </Link>
              
              <Link to="/policies/workplace-safety" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Next: Workplace Safety <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LeavePolicyPage 